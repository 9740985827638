
import {
  FilterOptionsResponse,
  DeploymentsServiceResponse,
  GetDeploymentsInput,
  ServicesResponse,
  GetBuildInput,
  GetServicesInput,
  GetServicesListInput,
  BuildPopulationResult,
  PullRequestResponse,
  PullRequestPopulationEntity,
  ServicesPopulation,
  GetPullRequestInput,
  GetDeploymentsListInput,
  GetTestResultsInput,
  TestResultsEntity,
  TestResultsResponse
} from '../../models';

import { post } from '../OdataApiService';

export const HERA_FILTER_TABLE = '/HeraGetFilterOptionsRequest';

export const HERA_DEPLOYMENTS = '/HeraGetDeploymentsRequest';
export const HERA_DEPLOYMENTS_LIST = '/HeraGetDeploymentsListRequest';
export const HERA_RELEASES_LIST_FOR_DEPLOYMENTS = '/HeraGetReleasesListForDeployments';

export const HERA_SERVICES = '/HeraGetServicesRequest';
export const HERA_SERVICES_LIST = '/HeraGetServicesListRequest';

export const HERA_BUILD = '/HeraGetBuildRequest';
export const HERA_PULL_REQUEST = '/HeraGetPullRequest';
export const HERA_PULL_REQUEST_LIST = '/HeraGetPullRequestListRequest';
export const HERA_TEST_RESULTS = '/HeraGetTestResults';
export const HERA_TEST_RESULTS_LIST = '/HeraGetTestResultListRequest';

// Timeout for export APIs in milliseconds
const EXPORT_APIS_TIMEOUT = 90000;

export const ComplianceViewerAPI = {
  async getFilterTable(): Promise<FilterOptionsResponse> {
    return await post<Record<string, never>, FilterOptionsResponse>(
      HERA_FILTER_TABLE,
      {}
    );
  },
  async getDeploymentPopulationData(getDeploymentsInput: GetDeploymentsInput): Promise<DeploymentsServiceResponse> {
    return await post<GetDeploymentsInput, DeploymentsServiceResponse>(
      HERA_DEPLOYMENTS,
      getDeploymentsInput
    );
  },
  async getDeploymentPopulationList(getDeploymentsListInput: GetDeploymentsListInput): Promise<string> {
    return await post<GetDeploymentsListInput, string>(
      HERA_DEPLOYMENTS_LIST,
      getDeploymentsListInput,
      undefined,
      {
        // Increase timeout for deployments list export
        'timeout': EXPORT_APIS_TIMEOUT
      }
    );
  },
  async getReleasesPopulationListForDeployments(getDeploymentsListInput: GetDeploymentsListInput): Promise<object[]> {
    return await post<GetDeploymentsListInput, object[]>(
      HERA_RELEASES_LIST_FOR_DEPLOYMENTS,
      getDeploymentsListInput,
      undefined,
      {
        // Increase timeout for deployments list export
        'timeout': EXPORT_APIS_TIMEOUT
      }
    );
  },
  async getServicesPopulationData(GetServicesInput: GetServicesInput): Promise<ServicesResponse> {
    return await post<GetServicesInput, ServicesResponse>(
      HERA_SERVICES,
      GetServicesInput
    );
  },
  async getServicesPopulationList(GetServicesListInput: GetServicesListInput): Promise<ServicesPopulation[]> {
    return await post<GetServicesListInput, ServicesPopulation[]>(
      HERA_SERVICES_LIST,
      GetServicesListInput,
      undefined,
      {
        // Increase timeout for services list export
        'timeout': EXPORT_APIS_TIMEOUT
      }
    );
  },
  async getBuildInputData(GetBuildInput: GetBuildInput): Promise<BuildPopulationResult> {
    return await post<GetBuildInput, BuildPopulationResult>(
      HERA_BUILD,
      GetBuildInput
    );
  },
  async getPullRequestPopulationData(getBuildInputData: GetPullRequestInput): Promise<PullRequestResponse> {
    return await post<GetBuildInput, PullRequestResponse>(
      HERA_PULL_REQUEST,
      getBuildInputData
    );
  },
  async getPullRequestPopulationList(getBuildInputData: GetBuildInput): Promise<PullRequestPopulationEntity[]> {
    return await post<GetBuildInput, PullRequestPopulationEntity[]>(
      HERA_PULL_REQUEST_LIST,
      getBuildInputData,
      undefined,
      {
        // Increase timeout for pull requests list export
        'timeout': EXPORT_APIS_TIMEOUT
      }
    );
  },
  async getTestResultsData(getBuildInputData: GetTestResultsInput): Promise<TestResultsResponse> {
    return await post<GetBuildInput, TestResultsResponse>(
      HERA_TEST_RESULTS,
      getBuildInputData
    );
  },
  async getTestResultsList(getBuildInputData: GetBuildInput): Promise<TestResultsEntity[]> {
    return await post<GetBuildInput, TestResultsEntity[]>(
      HERA_TEST_RESULTS_LIST,
      getBuildInputData,
      undefined,
      {
        // Increase timeout for test results list export
        'timeout': EXPORT_APIS_TIMEOUT
      }
    );
  }
};
